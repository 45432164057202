// 担保动态
<template>
  <div class="guaranteeState">
    <div class="add">
      <div class="addbutton" @click="addInfo">
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <base-table
      :columns="GuaranteeStateColumn"
      :tableAttrs="{
        data: newGuaranteeStateData,
        stripe: true,
      }"
      :isCaculateHeight="false"
      :showPage="false"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="guaranteeDynamics" slot-scope="scope">
        <el-input
          v-model="scope.row.guaranteeDynamics"
          type="textarea"
          row=1
          maxlength="100"
          show-word-limit
          placeholder="请输入"
        ></el-input>
      </template>
      <template slot="action" slot-scope="scope">
        <icon-button
          content="删除"
          icon="iconfont iconshanchu1"
          @click="del(scope.row)"
        />
      </template>
    </base-table>
  </div>
</template>
<script>
import baseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { GuaranteeStateTable } from '../utils/financing-info-config'
import { dueDiligenceApi } from '@/api/businessApi'

export default {
  props: {
    guaranteeStateData: Array
  },
  components: { baseTable, IconButton },
  data () {
    return {
      // newGuaranteeStateData: [] // 表格数据
    }
  },
  computed: {
    GuaranteeStateColumn () {
      return GuaranteeStateTable(this)
    },
    newGuaranteeStateData: {
      get () {
        return this.guaranteeStateData
      },
      set (val) {
        this.$emit('changeGuaranteeState', val)
      }
    }
  },
  watch: {

  },
  methods: {

    // 增加信息
    addInfo () {
      this.newGuaranteeStateData.push({ add: true })
    },
    // 删除担保信息
    del (data, index) {
      // 如果是新增地直接删除了
      if (data.add) {
        this.newGuaranteeStateData.splice(index, 1)
        return
      }
      dueDiligenceApi.delGuarantee({ keyId: data.dueDiligenceId }).then(res => {
        if (res.data) {
          this.newGuaranteeStateData.splice(index, 1)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.guaranteeState {
  padding: 10px;

}
.add {
    height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .addbutton {
      margin-left: 20px;
      background: #4A7CF0;
      width: 20px;
      height: 20px;
      color: #fff;
      text-align: center;
      line-height: 20px;
      cursor: pointer;
      &:hover {
        background-color: #6996FF;
      }
    }
  }
</style>
